const es = {
  translation: {
    common: {
      spanish: 'Español',
      english: 'English',
      optional: 'opcional',
      continue: 'Continuar',
      errors: {
        required: 'Campo requerido',
      },
      countryPA: 'Panamá',
      countryCO: 'Colombia',
      countryDOM: 'República Dominicana',
      countryGTM: 'Guatemala',
      countryCR: 'Costa Rica',
      countryPR: 'Puerto Rico',
      mobileApp: 'aplicación móvil',
      website: 'página web',
      hello: 'Hola',
    },
    header: {
      title: 'Reserva tours y actividades inolvidables',
      experienceTitle:
        'Gotuuri: Tours, Actividades y Atracciones Únicas en {{title}}',
      metaTitle: 'Gotuuri: Tours, Actividades y Atracciones Únicas',
      metaDescription:
        'Reserva las mejores excursiones y actividades únicas en diversos destinos. Con Gotuuri, explora atracciones y vive experiencias auténticas.',
    },
    navbar: {
      home: 'Inicio',
      aboutUs: 'Acerca de nosotros',
      contact: 'Contacto',
      language: 'Idioma',
      download: 'Descarga nuestra app',
      signUp: 'Regístrate',
      signIn: 'Inicia sesión',
      signInUp: 'Inicia sesión o regístrate',
      profile: 'Perfil',
      favorites: 'Favoritos',
      agenda: 'Agenda',
      signOut: 'Cerrar sesión',
      signOutSuccess: 'Sesión cerrada exitosamente',
      giftcards: 'Tarjetas de regalo',
      referAndWins: 'Comparte y gana $5',
      referAndWinsDescription:
        'Comparte y gana $5 cuando tu referido haga su primera compra',
      copyLink: 'Copiar link',
      destinations: 'Destinos',
    },
    contact: {
      title: 'Contacto',
      contactUs: 'Contáctanos!',
      address: 'Dirección',
      phone: 'Teléfono',
      email: 'Email',
    },
    faqs: {
      faqs: 'FAQs',
      faqsDescription: 'Preguntas frecuentes',
    },
    aboutUs: {
      title: 'Acerca de nosotros',
      titleMobile: 'Sobre nosotros',
      description:
        'Explora Gotuuri, ¡tu portal a experiencias locales autenticas! Nuestra vibrante plataforma te conecta con guías certificados, pequeños negocios y locales listos para revelar las actividades más auténticas y los tesoros ocultos de sus destinos.',
      description2:
        'En Gotuuri, nos enfocamos en experiencias únicas que te permitirán adentrarte en la cultura de cada destino. Interactúa con las comunidades locales, prueba plato tradicionales, baila al ritmo de la música regional y disfruta de los paisajes naturales tal como lo hacen los locales. Nuestra misión no es solo mostrar el mundo, sino enriquecerlo empoderando a las economías locales. Únete a nosotros para vivir profundamente, explorar responsablemente y ayudar a apoyar a las comunidades que hacen único a cada lugar. ¡Vive todo, reserva todo y descubre el verdadero espíritu de cada destino con nosotros!',
    },
    notFound: {
      oops: 'UPS!',
      title: 'Página no encontrada',
      sorry: 'Lo siento, no pudimos encontrar esta página.',
      message:
        'No logramos encontrar la página o enlace buscado. <br> Asegúrate de introducirla de manera correcta o dirígete a',
      homepage: 'gotuuri.com',
    },
    bannerApps: {
      title: 'Descarga nuestra App',
      description:
        'Somos una plataforma digital donde visitantes y residentes pueden conectar con microempresarios de turismo, guías y personas apasionadas por nuestro país (tuuris).',
    },
    resumeCard: {
      experienceDetails: 'Detalles de la experiencia',
      cancelationPolicy: 'Política de cancelación',
      knowYourCoverage: 'Conoce tu cobertura',
      priceDetail: 'Detalles de precio',
      pricePerPackage: 'Precio x paquete',
      subTotal: 'Subtotal',
      couponDiscount: 'Descuento x cupón',
      fee: 'Tarifa',
      total: 'Total',
      addCoupon: 'Agregar cupón',
      couponExpired: 'Este cupón ha expirado.',
      couponAffiliateInvalid: 'Este cupón no es válido.',
      couponNotElegiblePrice:
        'El precio de la experiencia supera el límite permitido para este cupón. Ajusta la selección e inténtalo de nuevo.',
      couponHasMaxDiscount:
        'El % de descuento se ha ajustado, ya que el monto original excede el límite permitido. ¡Continúa con tu compra!',
      couponHasMaxUsesPerUser:
        'Este cupón tiene limitaciones de uso por usuario.',
      couponInvalid:
        'El cupón no es válido para esta experiencia. Revisa las condiciones de la oferta.',
      apply: 'Aplicar',
      addDiscountCoupon: 'Ingresar cupón de descuento',
      invalidCoupon: 'Cupón invalido',
      couponRequired: 'Cupón requerido',
      pricePerSeniors_one: 'Precio x {{count}} adulto mayor',
      pricePerSeniors_other: 'Precio x {{count}} adultos mayores',
      pricePerAdults_one: 'Precio x {{count}} adulto',
      pricePerAdults_other: 'Precio x {{count}} adultos',
      pricePerChildren_one: 'Precio x {{count}} niño',
      pricePerChildren_other: 'Precio x {{count}} niños',
      pricePerBabies_one: 'Precio x {{count}} bebe',
      pricePerBabies_other: 'Precio x {{count}} bebes',
      pricePerParticipants_one: 'Precio x {{count}} participantes',
      additionals: 'Adicionales',
      gotuuriCredit: 'Crédito Gotuuri',
      totalToPay: 'Total a pagar',
      rangeDiscount: 'Descuento',
    },
    home: {
      topDestinationsPA: 'Principales destinos en Panamá',
      topDestinationsCO: 'Principales destinos en Colombia',
      topDestinationsDOM: 'Principales destinos en República Dominicana',
      topDestinations: 'Principales destinos',
      destinations: 'Destinos',
      highlights: 'Destacados',
      allHighlights: 'Todos los destacados',
      allExperiences: 'Todas las experiencias',
      viewAll: 'Ver todos',
    },
    giveAnExperience: {
      title: 'El mejor regalo: una experiencia inolvidable.',
      chooseATheme: 'Elige un tema',
      chooseAnAmount: 'Elige un monto',
      chooseTheDeliveryDate: 'Elige la fecha de entrega',
      enterDeliveryDetails: 'Ingresa los detalles de entrega',
      nameOfTheLuckyOne: 'Nombre del afortunado/a',
      luckyMail: 'Correo del afortunado/a',
      senderDetails: 'Datos del remitente',
      senderName: 'Nombre',
      senderEmail: 'Correo',
      addAPersonalizedMessage: 'Añade un mensaje personalizado',
      yourMessage: 'Tu mensaje',
      immediateDelivery: 'Entrega inmediata',
      selectDate: 'Escoger fecha',
      errors: {
        invalidAmount: 'El monto debe ser entre $5.00 y $200.00',
      },
      buyGiftCard: 'Comprar tarjeta de regalo',
    },
    redeem: {
      title: 'Redime tu tarjeta de regalo',
      form: {
        codeLabel: 'Ingresa el código que aparece en tu tarjeta de regalo',
        submitButton: 'Redimir',
      },
      redeemError: 'Error al redimir la tarjeta de regalo.',
      redeemSuccess: '¡Tarjeta de regalo canjeada con éxito!',
      checkBalance: 'Verificar saldo',
      info: 'Una vez que redimas la tarjeta de regalo, el monto se transferirá a tu crédito de Gotuuri. Tu crédito se aplicará en su totalidad a la compra de tu elección. En caso de que la compra sea por un valor menor al de tu crédito, la diferencia permanecerá en tu cuenta.',
    },
    balance: {
      title: 'Crédito disponible',
      info: 'Este crédito se aplicará en su totalidad y de manera automática a tu próxima compra, sin embargo siempre puedes decidir no aplicarlo. En caso de que la compra sea por un valor menor al de tu crédito, la diferencia permanecerá en tu cuenta. Toma en cuenta que tu crédito en Gotuuri no tiene fecha de expiración, no es transferible y no puede ser redimido por efectivo.',
    },
    experienceList: {
      priceStart: 'Desde',
      pricePerGroup: 'Precio por grupo',
    },
    footer: {
      gotuuri: 'Gotuuri',
      AboutUs: 'Acerca de nosotros',
      contact: 'Contacto',
      support: 'Soporte',
      blog: 'Blog',
      termsAndConditions: 'Términos y condiciones',
      faqs: 'Preguntas frecuentes',
      paymentMethods: 'Métodos de pago',
      privacyPolicies: 'Políticas de privacidad',
      giveAnExperience: 'Tarjetas de Regalo',
      downloadApp: 'Descarga la App',
      tuuriRegister: 'Aplica como Tuuri',
    },
    searchBar: {
      lookingFor: '¿Qué estás buscando?',
      search: 'Buscar',
      resultsFound: '{{quantity}} resultados encontrados',
      selectDestination: 'Seleccionar destino',
    },
    checkEmailForm: {
      signInUp: 'Inicia sesión o regístrate',
      email: 'Correo',
      continue: 'Continuar',
      signInWith: 'O ingresa con',
      errors: {
        required: 'Campo requerido',
        invalidEmail: 'Email con formato incorrecto',
      },
      freeCredit:
        'Regístrate en Gotuuri y recibe <span>$15 gratis</span> en créditos para disfrutar de nuestras experiencias.',
    },
    signInForm: {
      signIn: 'Inicia sesión',
      password: 'Contraseña',
      forgotPassword: '¿Olvidaste tu contraseña?',
      signInButton: 'Iniciar sesión',
      signInSuccess: 'Sesión iniciada exitosamente',
      errors: {
        required: 'Campo requerido',
        invalidPassword:
          'Debe tener minimo 8 caracteres, mayúscula, minúscula, número.',
        incorrectPassword: 'Contraseña incorrecta',
      },
    },
    signUpForm: {
      signUp: 'Regístrate',
      firstName: 'Nombre',
      lastName: 'Apellido',
      email: 'Correo',
      phone: 'Teléfono',
      password: 'Contraseña',
      signUpButton: 'Continuar',
      disclosureFirstPart:
        'He leído y acepto los <a target="_blank" href="/terms-and-conditions">Términos y Condiciones</a> y la <a target="_blank" href="/privacy-policies">Política de Privacidad</a> de Gotuuri.',
      disclosureSecondPart: 'términos y condiciones.',
      termsConsentError:
        'Para registrarte, por favor acepta nuestros términos.',
      signUpSuccess: 'Registro exitoso',
      errors: {
        required: 'Campo requerido',
        invalidFirstName: 'Debe contener al menos 3 caracteres validos.',
        invalidAddressMin: 'Debe contener al menos 5 caracteres validos.',
        invalidAddressMax: 'Debe contener menos de 55 caracteres.',
        invalidFormat: 'Formato inválido',
        invalidLastName: 'Debe contener al menos 3 caracteres validos.',
        invalidDni: 'Debe contener al menos 3 caracteres validos.',
        invalidEmail: 'Formato inválido de correo',
        invalidPhone: 'Teléfono inválido',
        invalidPassword:
          'Debe contener mínimo 8 caracteres, 1 mayúscula, 1 minúscula, 1 número.',
        verifyInformation:
          'No se pudo completar su registro, verifique la información e intente nuevamente.',
      },
    },
    signUpSuccess: {
      welcome: 'Bienvenido a Gotuuri',
      description:
        'Donde podrás descubrir y reservar experiencias auténticas en Panamá.',
      description1:
        'Te recomendamos terminar de completar tu perfil, para que los Tuuris puedan ponerse en contacto contigo',
      continue: 'Continuar',
      description2:
        'Completa tu perfil para que nuestros Tuuris puedan ponerse en contacto contigo.',
    },
    experience: {
      description: 'Descripción',
      detailsTitle: 'Detalles',
      similarActivities: 'Actividades similares',
      includeTitle: '¿Qué incluye?',
      and: 'y',
      operatedBy: 'Operado por:',
      details: {
        type: 'Tipo:',
        duration: 'Duración:',
        durationData_one: '{{count}} hora',
        durationData_other: '{{count}} horas',
        customerReviews: 'Reseñas de clientes',
        customerReviewsComments_one: '{{count}} comentario',
        customerReviewsComments_other: '{{count}} comentarios',
        lang: 'Idioma:',
        difficulty: 'Dificultad:',
        minAgeAllowed: 'Edad mínima permitida:',
        years: ' años',
        minParticipants: 'Mínimo de participantes:',
        maxParticipants: 'Máximo de participantes:',
        meetingPoint: 'Punto de encuentro:',
        private: 'Privada',
        shared: 'Compartida',
        spanish: 'Español',
        english: 'Ingles',
        german: 'Alemán',
        french: 'Francés',
        portuguese: 'Portugues',
      },
      difficultyLevels: {
        easy: 'Fácil: No requiere experiencia previa.',
        medium: 'Medio: Requiere algo de experiencia o esfuerzo moderado.',
        hard: 'Difícil: Exige habilidad, resistencia o experiencia avanzada.',
        expert:
          'Experto: Para personas con alto nivel de destreza o conocimiento especializado.',
        notApply: 'No aplica',
      },
      difficultyDescriptions: {
        easy: 'No requiere experiencia previa..',
        medium: 'Requiere algo de experiencia o esfuerzo moderado.',
        hard: 'Exige habilidad, resistencia o experiencia avanzada.',
        extreme:
          'Para personas con alto nivel de destreza o conocimiento especializado.',
      },
      cancelationPoliciesValues: {
        noRefundable: 'No Reembolsable',
        refundable24:
          'Obtén un reembolso completo si cancelas al menos 24 horas antes de que comience la experiencia.',
        refundable48:
          'Obtén un reembolso completo si cancelas al menos 48 horas antes de que comience la experiencia.',
        refundable72:
          'Obtén un reembolso completo si cancelas al menos 72 horas antes de que comience la experiencia.',
        refundable7:
          'Obtén un reembolso completo si cancelas al menos 7 días antes de que comience la experiencia.',
        refundable15:
          'Obtén un reembolso completo si cancelas al menos 15 días antes de que comience la experiencia.',
      },
      included: {
        photos: 'Fotos',
        drinks: 'Bebidas',
        equipments: 'Equipo',
        snacks: 'Alimento',
        tickets: 'Tickets',
        transport: 'Transporte',
      },
      recommendations: 'Recomendaciones',
      noIncludes: 'No incluye',
      cancellationPolicies: 'Políticas de cancelación',
      participantsRequirements: 'Requerimientos de participantes',
      additionalQuestions: '¿Preguntas adicionales?',
      additionalDetails:
        'Si tienes alguna consulta adicional, no dudes en contactarnos:',
      customerReview: 'Reseñas',
      viewMoreReviews: 'Ver más reseñas',
      viewLessReviews: 'Ver menos reseñas',
      whatsapp: 'WhatsApp',
      email: 'Email',
      location: 'Ubicación',
      viewMore: 'Ver más',
      showMore: 'Mostrar más',
      showLess: 'Mostrar menos',
      supportMessage: 'Hola%20Gotuuri👋🏼%20Necesito%20ayuda.',
      supportMessage2: 'Hola Gotuuri, tengo preguntas sobre {{expName}}.',
      chubbTitle: 'Cobertura de seguro',
      chubb:
        'Reserva con Gotuuri y benefíciate de una cobertura de la empresa de seguros Chubb durante toda tu experiencia,',
      chubb2: 'revisa los términos y montos asegurados aquí.',
      lookForChubb: 'Revisa los términos y montos asegurados ',
      here: 'aquí',
      schedule: 'Horario:',
      separator: ' a las ',
      all_days: 'Todos los días',
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      sunday: 'Domingo',
    },
    bookingCard: {
      perGroup: 'por grupo',
      perPerson_one: 'por persona',
      perPerson_other: 'Total',
      total: 'Total',
      selectDate: 'Seleccionar fecha',
      selectTime: 'Seleccionar hora',
      noHoursAvailable: 'No hay horarios disponibles',
      firstSelectDate: 'Primero selecciona la fecha',
      firstSelectHour: 'Primero selecciona la hora',
      participants: 'Participantes',
      seniors: 'Adultos mayores',
      age65More: '65 años o más',
      adults: 'Adultos',
      age13More: '13 años o más',
      children: 'Niños',
      age2to12: '2 a 12 años',
      babies: 'Bebés',
      ageLess2: 'Menos de 2 años',
      age65: '65+ años',
      perGroupExperience:
        '{{company}} ofrece esta experiencia para un grupo de hasta {{maxPersons}} personas por un precio de ${{pricePackage}}.',
      book: 'Reservar actividad',
      booking: 'Reservación',
      schedules: 'Ver horarios',
      dateAndHour: 'Fecha y hora',
      priceStart: 'por persona',
      pricePerGroup: 'Precio por grupo',
      next: 'Siguiente',
      signIn: 'Regístrate para reservar',
      signInMobile: 'Regístrate y reserva',
      addOns: 'Adicionales',
      whyUs: '¿Por qué Gotuuri?',
      accidentInsurance: 'Seguro contra accidentes durante la experiencia.',
      cancelationPoliciesValues: {
        noRefundable: 'No reembolsable.',
        refundable24:
          'Cancelación gratuita si cancelas con 24 horas de anticipación.',
        refundable48:
          'Cancelación gratuita si cancelas con 48 horas de anticipación.',
        refundable72:
          'Cancelación gratuita si cancelas con 72 horas de anticipación.',
        refundable7:
          'Cancelación gratuita si cancelas con 7 días de anticipación.',
        refundable15:
          'Cancelación gratuita si cancelas con 15 días de anticipación.',
      },
      personalizedAttention: 'Atención personalizada vía correo o Whatsapp.',
      priceDiscountGroupsInfo:
        'El precio varía según la cantidad de participantes',
      priceInDollars: 'Precios mostrados en dólares (USD)',
    },
    payment: {
      completeYourPurchase: 'Finaliza tu compra',
      payWith: 'Pagar con',
      creditCard: 'Tarjeta de crédito',
      paypal: 'PayPal',
      billingContactData: 'Datos de facturación y contacto',
      billingData: 'Datos de facturación',
      cardNumber: 'Número de tarjeta',
      ownerName: 'Nombre del titular',
      expirationDate: 'Expira (MM/AA)',
      cvvCode: 'Código de seguridad',
      name: 'Nombre',
      lastName: 'Apellido',
      address: 'Dirección',
      country: 'País o región',
      city: 'Ciudad/Estado/Provincia',
      missingCity: 'Debe seleccionar una ciudad',
      state: 'Estado',
      postalCode: 'Código postal',
      email: 'Correo',
      phone: 'Teléfono',
      cardNumberPlaceHolder: 'xxxx-xxxx-xxxx-xxxx',
      ownerNamePlaceHolder: 'Nombre completo',
      expirationDatePlaceHolder: 'MM/AA',
      cvvCodePlaceHolder: 'CVV',
      namePlaceHolder: 'Jhon',
      lastNamePlaceHolder: 'Doe',
      addressPlaceHolder: 'Calle, barrio, número de casa',
      statePlaceHolder: 'Dirección',
      postalCodePlaceHolder: 'Código postal',
      emailPlaceHolder: 'Correo',
      invalidEmail: 'Correo con formato incorrecto',
      invalidFormat: 'Formato incorrecto',
      disclosure:
        'Recibirás la confirmación en un máximo de 24 horas. No se realizarán los cargos a tu tarjeta hasta que tu solicitud esté completamente confirmada.',
      specialChars: 'No se permiten números ni caracteres especiales',
      required: 'Campo requerido',
      select: 'Selecciona',
      confirmPayment: 'Solicitar reserva',
      continuePayment: 'Continuar con el pago',
      pay: 'Pagar',
      paymentSuccess: '¡Tu solicitud ha sido enviada con éxito!',
      viewAgenda: 'Ver agenda',
      paymentError:
        'El pago no pudo ser procesado. Por favor intenta de nuevo.',
      userBlocked:
        'Su cuenta ha sido bloqueada. Por favor, contactese con nosotros.',
      creditGotuuri: 'Crédito de Gotuuri',
      buy: 'Comprar',
      successfulGiftCardPurchase: 'Compra exitosa',
      detailGiftCardPurchase: 'Te llegará un correo con los detalles.',
      ThanksForChoosingUs: '¡Gracias por elegirnos!',
      applyAvailableBalance: 'Aplicar crédito de Gotuuri',
      authFailedTitle: 'Alerta: Problema de autorización bancaria',
      authFailedConfirm: 'Usar otro método de pago',
      authProcess: 'Proceso de autenticación',
      authProcessDescription:
        'Por tu seguridad, requerimos que sigas las instrucciones de tu banco antes de validar la compra.',
      authProcessing: 'Procesando autenticación',
      authFailed: 'Autenticación fallida',
      authIssue:
        'La transacción con esta tarjeta no se ha podido procesar. Por favor, pruebe con otra tarjeta de crédito o método de pago.',
      personalData: 'Información personal',
    },
    recoverPasswordForm: {
      recoverPassword: 'Recuperar contraseña',
      email: 'Correo',
      description:
        'Te enviaremos una contraseña temporal al correo asociado a tu cuenta, que podrás utilizar para restablecer tu contraseña.',
      recoverButton: 'Recuperar',
      goBackButton: 'Atrás',
    },
    rating: {
      veryBad: 'Muy mala',
      bad: 'Mala',
      regular: 'Regular',
      good: 'Buena',
      veryGood: 'Muy Buena',
    },
    profile: {
      profile: 'Perfil',
      personalInformation: 'Información personal',
      userUpdated: 'Información personal actualizada con éxito',
      verifyInformation: 'Por favor intenta de nuevo.',
      fullName: 'Nombre completo',
      nameLabel: 'Nombre',
      lastName: 'Apellido',
      jhon: 'Jhon',
      doe: 'Doe',
      jhonDoe: 'Jhon Doe',
      phone: 'Teléfono',
      birthDate: 'Fecha de nacimiento',
      dni: 'Cédula',
      address: 'Dirección',
      country: 'País',
      cancel: 'Cancelar',
      edit: 'Editar',
      save: 'Guardar',
      saving: 'Guardando',
      selectCountry: 'Seleccionar país',
      preferences: 'Preferencias',
      language: 'Idioma',
      security: 'Seguridad',
      changePassword: 'Cambiar contraseña',
      oldPassword: 'Contraseña actual',
      newPassword: 'Contraseña nueva',
      repeatPassword: 'Confirmar contraseña',
      disableAccount: 'Desactivar o eliminar cuenta',
      aboutDeleteAccount: 'Acerca de solicitudes de eliminación de cuenta',
      deleteAccountDisclosure1:
        'Si en el momento de solicitar desactivar o eliminar tu cuenta, tienes una reserva confirmada, ya sea como Operador o cliente, no vamos a poder llevar a cabo la solicitud. Es importante primero cancelar la reserva, tomando en cuenta las políticas de cancelación aplicables, para que la solicitud se haga efectiva.',
      deleteAccountDisclosure2:
        'Una vez tu solicitud sea tramitada, vamos a borrar tus datos personales, a excepción de cualquier información que estemos legalmente autorizados de conservar, de acuerdo a nuestra Política de Privacidad.',
      whyDeleteAccount: '¿Por qué deseas eliminar tu cuenta?',
      deleteReason1: 'No me gustó Gotuuri.',
      deleteReason2: 'No uso la cuenta lo suficiente.',
      deleteReason3: 'Quiero eliminar una cuenta duplicada.',
      deleteReason4: 'Otras razones.',
      deleteAccount: 'Eliminar cuenta',
      deleteAccountConfirmation:
        'Procederás a eliminar tu cuenta, esta acción no se puede revertir ¿Seguro que deseas continuar?',
      noCancel: 'No, cancelar',
      yesDelete: 'Sí, eliminar',
      accountDeleted: 'Cuenta eliminada con éxito.',
      goodbyeMessage:
        'Te extrañaremos, pero si cambias de parecer, estaremos aquí.',
      cantDeleteAccount:
        'No se puede eliminar la cuenta, tiene experiencias pendientes o en progreso.',
      errors: {
        generalError: 'Error: Por favor verifica la información',
        emptyFields: 'Este campo no puede estar vacío.',
        specialChars: 'No se permiten números ni caracteres especiales.',
        invalidPassword: 'Contraseña incorrecta.',
        passwordNotMatch: 'Las contraseñas no coinciden',
      },
      creditAndGiftCard: 'Créditos y tarjetas de regalos',
      buyGiftCard: 'Buy gift card',
      redeem: 'Redimir tarjeta de regalo',
      balance: 'Saldo disponible',
    },
    search: {
      noSearchResults: 'No se han encontrado resultados para tu búsqueda',
      translationWordsCorrect:
        'Asegúrate de que todas las palabras estén escritas correctamente.',
      translationUseGeneralKeywords:
        'Prueba con palabras claves más generales.',
    },
    filter: {
      filters: 'Filtros',
      destination: 'Destino',
      country: 'País',
      language: 'Idioma',
      properties: 'Características',
      privateTours: 'Tours privados',
      groupTours: 'Tours grupales',
      ratingInt: 'Clasificación de 4+ estrellas',
      includeTransport: ' Transporte incluido',
      pastimes: 'Pasadías',
      acceptsChildren: 'Acepta niños',
      spanish: 'Español',
      english: 'Inglés',
      german: 'Alemán',
      french: 'Francés',
      hour_one: '{{count}} hrs',
      hour_other: '{{count}} hrs',
      maximumHours: '{{hour}}+ hrs',
      whereAreWeGoing: '¿A dónde vamos?',
      participants: 'Participantes',
      categories: 'Categorías',
      duration: 'Duración',
      rangePrice: 'Rango de precio',
      deleteFilters: 'Borrar filtros',
      applyFilter: 'Aplicar filtros',
      tryAdjustingFilters:
        'Prueba ajustando tus filtros o utilizando términos de búsqueda diferentes.',
    },
    selectDate: {
      day: 'Día',
      month: 'Mes',
      year: 'Año',
    },
    agendaPage: {
      agenda: 'Agenda',
      tuuri: 'Operador',
      reservedExperiences: 'Experiencias reservadas',
      upcomingPurchases: 'Próximas reservas',
      pastPurchases: 'Anteriores y canceladas',
      nothingReserved:
        'Todavía no tienes experiencias agendadas. ¡Comienza a explorar!',
      purchaseCard: {
        tuuri: 'Tuuri',
        date: 'Fecha',
        orderNumber: 'Número de orden',
        participants: 'Participantes',
        total: 'Total',
        experienceNotAvailable: 'Experiencia no disponible',
        tuuriNotAvailable: 'Operador no disponible',
      },
      statusBadge: {
        statusCreated: 'Por confirmar',
        statusConfirmed: 'Confirmada',
        statusInProgress: 'En progreso',
        statusCompleted: 'Completada',
        statusCanceledByCustomer: 'Cancelada',
        statusCanceledByTuuri: 'Cancelada por tuuri',
        statusCanceledByAdministration: 'Cancelada por administración',
        unknown: 'Desconocido',
      },
      purchaseButtons: {
        purchaseCanceled: 'Experiencia cancelada',
        cancelButton: {
          cancel: 'Cancelar',
          disclosure:
            'Si has tenido que cancelar fuera del periodo de cancelación gratuita debido a un Evento de Fuerza Mayor o Contratiempo de Experiencia, como se detalla en nuestros Términos y Condiciones, por favor, contáctanos escribiendo a help@gotuuri.com.',
          confirmation: '¿Seguro que deseas cancelar esta experiencia?',
          yes: 'Si',
          no: 'No',
        },
        reviewButton: {
          rate: 'Calificar',
          rateYourExperience: 'Califica tu experiencia',
          ratingSelect: 'Por favor califica la experiencia del 1 al 5.',
          ratingComment:
            'Si te gustaría dejar una reseña pública, para que otros clientes la vean, puedes hacerlo aquí.',
          ratingPrivateComment:
            'Si tienes algún otro comentario o sugerencia, puedes dejarlo aquí.',
          rateSend: 'Enviar',
          thanksFirst: '¡Gracias por tus',
          thanksSecond: 'comentarios!',
          continue: 'Continuar',
          error: 'No se ha podido enviar su calificación',
        },
        viewMoreButton: {
          type: 'Tipo',
          private: 'Privada',
          shared: 'Compartida',
          schedule: 'Horario',
          date: 'Fecha',
          participants: 'Participantes',
          adults: 'Adultos',
          children: 'Niños',
          babies: 'Bebés',
          addons: 'Adicionales',
          pickup: 'Punto de recogida',
          meeting: 'Punto de encuentro',
          reference: 'Referencia',
          viewDetails: 'Ver detalles',
          order: 'Orden',
          tuuri: 'Tuuri',
          total: 'Total',
        },
      },
    },
    destinationPage: {
      viewMore: 'Ver más',
      mayBeInterested: 'También te podría interesar',
      experienceQuantity: '{{quantity}} experiencias',
    },
    bookingDetailsPage: {
      experienceDetails: 'Detalles de la experiencia',
      hour: 'Hora: ',
      participants: 'Participantes: ',
      date: 'Fecha: ',
      transportationDetails: 'Detalles de traslado',
      meetingPoint: 'Punto de encuentro',
      pickupPoint: 'Introduce el punto de recogida:',
      reference: 'Referencia',
      pointOfReference: 'Más detalles',
      referencePlaceHolder:
        'Punto de recogida (Ej. Nombre del hotel o dirección).',
      pickupPointError: 'Porfavor ingresa una dirección valida',
      total: 'Total',
      continue: 'Continuar',
      principalData: 'Datos del principal',
      firstName: 'Nombre',
      lastName: 'Apellido',
      birthDate: 'Fecha de nacimiento',
      minDateError: 'Debe ser mayor de 18 años',
      maxDateError: 'Debe ser menor de 90 años',
      birthDateError: 'Fecha inválida',
      dni: 'Cédula o pasaporte',
      email: 'Correo',
      phone: 'Teléfono',
      address: 'Dirección',
      companionData: 'Datos de acompañantes',
      companion: 'Acompañante {{index}}',
      coverageArea: 'Zona de cobertura',
      dataDisclosure:
        '¡Necesitamos estos detalles para brindarte cobertura de seguro, manteniéndote protegido y respaldado durante toda tu aventura!',
      continueWithPayment: 'Continuar con el pago',
      requiredParticipantsFields: 'Campos requeridos',
      updateProfile: 'Actualizar perfil con estos datos',
      useForChubb: 'Utilizar datos de facturación como información de contacto',
      insuranceData: 'Datos de seguro y contacto',
      insuranceDataDescription:
        '¡Necesitamos estos datos para brindarte cobertura de seguro, manteniéndote protegido durante toda tu aventura!',
    },
    uniqueDatesRow: {
      dateFormat: "EEEE dd 'de' MMMM 'del' yyyy",
      noSchedules: 'No se encontraron horarios disponibles.',
    },
    favoritePage: {
      title: 'Favoritos',
      noFavorites:
        'Todavía no tienes experiencias favoritas. ¡Comienza a explorar!',
    },
    giftCard: {
      helloUser: 'Hola {{user}}',
      giftCardSender: '(Tu nombre)',
      giftCardMessage:
        '{{senderName}} ) te ha enviado una tarjeta de regalo para sumergirte en las auténticas aventuras disponibles en nuestra plataforma. ¡Aprovecha esta oportunidad para crear recuerdos inolvidables!',
      yourCode: 'Tu código es:',
      messageHere: 'Tu mensaje aquí',
      redeemYourCardIn: 'Redime tu tarjeta de regalo en nuestra',
      redeemYourCardInBlock1:
        'La tarjeta de regalo es válida para redimir, en nuestra',
      redeemYourCardInOr: 'o',
      redeemYourCardInBlock2:
        ', durante los próximos 6 meses. Luego de canjearla, el crédito no tiene fecha de vencimiento.',
      footerTimeDisclaimer:
        'La tarjeta de regalo es válida para redimir, en nuestra página web o aplicación móvil, durante los próximos 6 meses. Luego de canjearla, el crédito no tiene fecha de vencimiento.',
      footerDisclaimer:
        'Para canjear la tarjeta de regalo, ve a la sección "Tarjetas de regalo" en tu perfil y elige "Redimir tarjeta de regalo". Una vez redimas la tarjeta de regalo con el código de este correo, el crédito se añadirá a tu cuenta y se aplicará de manera automática a tu próxima compra, sin embargo siempre puedes decidir no aplicarlo.',
      giftCardRecipient: '(nombre afortunado)',
      stepsToRedeemYourGiftCard: 'Pasos para redimir tu tarjeta de regalo',
      step1:
        ' Ingresa a Gotuuri y regístrate con el correo al que te llegó la tarjeta de regalo.',
      step2:
        'Ingresa a tu perfil y ve a la sección de Créditos y tarjetas de regalos.',
      step3: 'Elige la opción "redimir tarjeta de regalo".',
      step4: 'Ingresa el código de tu tarjeta de regalo.',
      step5:
        '¡Listo! Ahora tendrás el crédito asociado a tu usuario y se aplicará de manera automática a tu próxima reserva.',
    },
    unSubscribePage: {
      emailPreferences: 'Preferencias de correo',
      emailPreferencesDescription:
        'Al hacer clic en "Cancelar suscripción", dejarás de recibir correos electrónicos de marketing de Gotuuri. Si prefieres seguir recibiendo correos, haz clic en “Mantener suscripción”.',
      successfullyUnsubscribe: 'Te has dado de baja correctamente',
      successfullyUnsubscribeDescription:
        'Has cancelado tu suscripción. A partir de ahora, dejarás de recibir correos de marketing de Gotuuri. ¡No será lo mismo sin ti! Te extrañaremos.',
      changeOfMindMessage: `Si te arrepentiste de cancelar tu suscripción, puedes volver a suscribirte haciendo clic en "Volver a suscribirme".`,
      unSubscribe: 'Cancelar suscripción',
      continueReceivingEmail: 'Mantener suscripción',
      reSubscribe: 'Volver a suscribirme',
      welcomeBack: '¡Bienvenido de vuelta!',
      thanksContinueReceivingEmails:
        'Has decidido continuar recibiendo nuestras comunicaciones por correo electrónico. ¡Gracias por seguir siendo parte de nuestra comunidad!',
    },
    map: {
      youMustSelectAValidLocationWithin:
        'Por favor selecciona una ubicación válida en',
    },
    redirect3ds: {
      allRight: '¡Todo listo!',
      allRightDescription:
        'Haz click en continuar para completar tu proceso de compra.',
    },
    tuuriRegister: {
      registerAsTuuri: 'Aplica como Proveedor',
      title: 'Únete a Gotuuri y conecta con miles de viajeros.',
      description:
        'Recibe soporte personalizado a través de teléfono. Whatsapp o correo electrónico, gestiona tus ofertas facilmente desde nuestra app móvil y destaca con recomendaciones exclusivas que mejorarán tus oportunidades en Gotuuri.',
      createPassword: 'Crear contraseña',
      submitButton: 'Aplicar',
      modalTitle: {
        success: '¡Proveedor registrado con éxito!',
        genericError: 'El registro no se completó.',
        conflictError: 'El registro no se completó.',
      },
      modalBody: {
        success: 'Te estaremos enviando más detalles a tu correo electrónico.',
        genericError:
          'Verifica que todos tus datos están bien y vuélvelo a intentar.',
        conflictError:
          'El correo ya está registrado. Por favor contactate con soporte.',
      },
      goHome: 'Ir a inicio',
      tryAgain: 'Intentar nuevamente',
    },
  },
}

export default es
