import { ExperienceCarousel } from '@/components/Carousels/ExperienceCarousel'
import { GeneralCarousel } from '@/components/Carousels/GeneralCarousel'
import { ExperienceList } from '@/components/ExperienceList'
import { FilterButton } from '@/components/FilterButton'
import HeaderSubBanner from '@/components/HeaderBanner/HeaderSubBanner'
import { Layout } from '@/components/Layout'
import { Loading } from '@/components/Loading'
import { NoFilterResults } from '@/components/NoFilterResults'
import { SearchBar } from '@/components/SearchBar'
import { CATEGORY_ID } from '@/constants/cookie'
import { ExperienceType } from '@/constants/generalCarousel'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { track } from '@/utils/analytics'
import { CleverEvents, googleEvents } from '@/utils/events'
import { getFilterParams } from '@/utils/getFilterParams'
import { useIsMobile } from '@/utils/mobile'
import { Button } from '@nextui-org/react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const CategoriesPage = () => {
  const {
    hooks: {
      setFavoriteExperience,
      getFilterExperiences,
      getCategoryProperties,
      getCarouselExperiences,
      setFilterData,
    },
    state: {
      experiences,
      experiencesProperties,
      loadingExperiences,
      user,
      filterData,
      pagination,
      hasSearchResults,
      categories,
    },
    state,
  } = useGotuuriContext()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { id } = useParams()
  const { search } = useLocation()
  const params = useMemo(() => new URLSearchParams(search), [search])
  const { urlParams, filtersCount } = getFilterParams(params)
  const navigate = useNavigate()

  const SearchBarCallBack = ({ filterUrl }) => {
    navigate(`?${filterUrl}`)
  }

  useEffect(() => {
    getCategoryProperties(id)
    if (experiencesProperties?.id !== id) {
      getCarouselExperiences({ type: ExperienceType.CATEGORIES, id })
    }
    loadExperiences({ newSearch: true })
    setFilterData({
      urlParams: urlParams,
      filtersCount:
        filtersCount - (!!urlParams[ExperienceType.CATEGORIES] ? 1 : 0),
    })
  }, [id, params])

  const loadExperiences = async ({ newSearch }) => {
    const searchText = urlParams.searchText
    const currentPage = newSearch ? 0 : pagination.currentPage ?? 0

    localStorage.setItem(CATEGORY_ID, id)
    const result = await getFilterExperiences({
      page: currentPage + 1,
      data: {
        ...urlParams,
        categories: id,
        ...(!!searchText && { searchText }),
      },
      newSearch: newSearch,
    })
    if (result) {
      googleEvents.CategoryView({
        experiences: result,
        page: currentPage + 1,
        state,
      })
    }
  }

  const bannerImage = experiencesProperties?.banner
  const titleName = experiencesProperties?.name ?? ''

  return (
    <div>
      <HeaderSubBanner
        bannerImage={bannerImage}
        loadingExperiences={loadingExperiences}
      >
        <h1 className="text-4xl text-center md:text-[56px] my-8 h-10 md:h-14">
          {titleName ?? ''}
        </h1>
        <SearchBar showSubmitButton callBack={SearchBarCallBack} />
      </HeaderSubBanner>
      <Layout title={titleName} image={bannerImage} description={titleName}>
        <div className="flex flex-col ">
          {!!!filterData?.urlParams?.searchText && <ExperienceCarousel />}
          <div
            className={`flex justify-between mt-6  md:mt-12 ${!hasSearchResults ? 'mb-0 md:mb-0' : 'mb-6 md:mb-6'}  items-center`}
          >
            <h2 className="text-xl md:text-3xl ">
              {!loadingExperiences
                ? !!hasSearchResults
                  ? t('home.allExperiences')
                  : t('search.noSearchResults')
                : null}
            </h2>
            <FilterButton
              iconOnly={isMobile}
              pathType={ExperienceType.CATEGORIES}
              defaultValue={ExperienceType.CATEGORIES}
              customFunction={() => {
                track('Filter View', {
                  screen: 'Category',
                })
              }}
            />
          </div>
          {!loadingExperiences ? (
            !!hasSearchResults ? (
              <>
                <ExperienceList
                  experiences={experiences?.data || []}
                  user={user}
                  setFavoriteExperience={setFavoriteExperience}
                />
                {pagination?.nextPage && (
                  <Button
                    radius="full"
                    className="!bg-black text-white w-36 mx-auto flex mt-16 md:mt-24"
                    onClick={() => {
                      loadExperiences({
                        newSearch: false,
                      })
                    }}
                  >
                    {t('destinationPage.viewMore')}
                  </Button>
                )}
              </>
            ) : (
              <NoFilterResults />
            )
          ) : (
            <Loading />
          )}
          <div className="mb-24 mt-8 md:mt-16">
            <div className="flex justify-between items-center">
              <h2 className={`text-2xl md:text-4xl my-6 md:my-4`}>
                {t('destinationPage.mayBeInterested')}
              </h2>
            </div>
            <GeneralCarousel
              data={categories || []}
              eventTrigger={(data) => {
                CleverEvents.CategoryView({
                  name: data.name,
                  id: data.id,
                  screen: 'Category',
                })
              }}
              type={ExperienceType.CATEGORIES}
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default CategoriesPage
